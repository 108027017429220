/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import '~medium-editor/dist/css/medium-editor.min.css';
@import '~medium-editor/dist/css/themes/default.min.css';

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}
.card {
    .card-body {
        padding: 15px;
    }
}

.sticky-column {
    background-color: #fff;
    position: sticky;
    left: -2px;
    z-index: 0;
  }
  .sticky-row {
    background-color: #fff;
    position: sticky;
    top: -1px;
    z-index: 1;
  }
  
  .my-custom-scrollbar {
    position: relative;
    max-height: 350px;
    overflow: auto;
  }
  
  .my-custom-scrollbar-obilasci {
    position: relative;
    max-height: 650px;
    overflow: auto;
  }

  .my-custom-scrollbar-woosync {
    position: relative;
    max-height: 500px;
    overflow: auto;
  }

  .my-custom-scrollbar-woosync-modal {
    position: relative;
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
  }

  .my-custom-scrollbar-nzp {
    position: relative;
    max-height: 300px;
    overflow: auto;
  }

  .table-wrapper-scroll-y {
    display: block;
  }
  .icon-button {
    .fa{
      vertical-align: middle;
      padding: 5px 3px;
    }
  }
  .pagination {
    flex-wrap: wrap;
  }

  body .dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table th, body .dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table td {
    background-color: initial;
}

.page-wrapper .page-body-wrapper .page-body {
  padding: 0;
}
.dashboard-shortcut {
  color: #212529 !important;
  background-color: #fdfeff !important;
  border-color: #fdfeff !important;
}
.dark-only{
  .history-row :hover {
    background-color: unset !important;
}
  .page-wrapper .page-header .header-wrapper li i {
    color: inherit !important; 
  }
  .dashboard-shortcut{
    background-color: #1d1e26 !important;
    border: none;
    color: #f8f8f8 !important;
  }
  .text-dark {
    color: unset !important;
  }
  .sticky-row{
    background-color: #1d1e26 !important;
  }
  .search-bar {
    background-color: #1d1e26 !important;
    ::placeholder {
      color: #f8f8f8 !important;
    }
    border: none !important;
  }
}
.table tbody tr td {
  vertical-align: middle !important;
}
.table thead tr td {
  vertical-align: middle !important;
}
.cdk-overlay-container {
  z-index: 999999 !important;
}
.dropdown {
  position: static;
}
.select-append {
  max-width: calc(100% - 69px);
}

app-number-input {
  width: 100%;
}

app-number-input-financije {
  width: 100%;
}

.mce-content-body {
  border: none !important;
  outline: none !important;
  font-size: 16px;
}