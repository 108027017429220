.ng-select {
    &.ng-select-single {
        .ng-select-container {
            height: 38px;
        }
    }

    .ng-select-container {
        border: 1px solid #ccc !important;
    }

    .ng-option-selected {
        .text-muted {
            color: #ffffff !important;
        }
    }
}

.input-group {

    &.custom-group {
        .form-control {
            border-right: none;
        }

        .input-group-append {
            span {
                color: black;
                background: none;
                border-left: none;
            }
        }

        &.readonly {
            span {
                background-color: #e9ecef;
            }
        }
    }

    .ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.dark-only {
    .input-group {
        &.custom-group {
            .input-group-append {
                span {
                    color: rgba(255, 255, 255, 0.7);
                    background-color: #1d1e26;
                    border-color: #1d1e26;
                }
            }
    
            &.readonly {
                span {
                    background-color: #1d1e26;
                }
            }
        }
    }
}
body .dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table th, body .dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table td {
    background-color: #262932;
}
body .dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-light td h6{
    color: #000 !important;
}