/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://angular.pixelstrap.com/cuba
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
*/

/* ---------------------
	*** Utils ***
-----------------------*/


@import "utils/variables";

:root {
  --theme-deafult: #7366ff;
  --theme-secondary: #f73164;

  --chart-border: #ecf3fa;
  --recent-chart-bg: #fcfcfd;
  --light-bg: #f6f7f9;
  --white: #fff;
  --light2: #f5f6f9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #f2f4f7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #f2f3f7;
  --course-bg: #f7f7f7;
  --balance-profie-bg: #e0dfef;
  --view-grid-bg: #ecf3fa80;
  --view-border-marker: #cfcdfc;
}

/* ---------------------
	*** Icons Scss ***
-----------------------*/
@import "vendors/whether-icon";
@import "vendors/flag-icon";
@import "vendors/icofont";
@import "vendors/themify";

/* ---------------------
	*** Base ***
-----------------------*/

@import "base/reset";
@import "base/typography";


/* ---------------------
	*** Components ***
-----------------------*/

@import "components/according.scss";
@import "components/alert.scss";
@import "components/avatars.scss";
@import "components/badge.scss";
@import "components/bookmark.scss";
@import "components/breadcrumb.scss";
@import "components/buttons.scss";
@import "components/card.scss";
@import "components/dropdown.scss";
@import "components/form-input.scss";
@import "components/form-wizard.scss";
@import "components/forms.scss";
@import "components/icons.scss";
@import "components/list.scss";
@import "components/loader.scss";
@import "components/popover.scss";
@import "components/print.scss";
@import "components/radio.scss";
@import "components/ribbon.scss";
@import "components/switch.scss";
@import "components/tab.scss";
@import "components/table.scss";
@import "components/touchspin.scss";

@import "components/angular-plugin.scss";
@import "components/calendar.scss";
@import "components/datatable.scss";
@import "components/datepicker.scss";

@import "components/typeahead-search";
@import "components/rating";


/* ---------------------
	*** Pages ***
-----------------------*/

@import "pages/blog.scss";
@import "pages/bookmark-app.scss";
@import "pages/cart.scss";
@import "pages/chart.scss";
@import "pages/chat.scss";
@import "pages/checkout.scss";
@import "pages/comingsoon.scss";
@import "pages/contacts.scss";
@import "pages/dashboard_2.scss";
@import "pages/dashboard_default.scss";
@import "pages/ecommerce.scss";
@import "pages/email-application.scss";
@import "pages/errorpage.scss";
@import "pages/faq.scss";
@import "pages/file.scss";
@import "pages/gallery.scss";
@import "pages/job-search.scss";
@import "pages/knowledgebase.scss";
@import "pages/language.scss";
@import "pages/learning.scss";
@import "pages/login.scss";
@import "pages/megaoption.scss";
@import "pages/order-history.scss";
@import "pages/page.scss";
@import "pages/pricing.scss";
@import "pages/progress.scss";
@import "pages/projectlist.scss";
@import "pages/social-app.scss";
@import "pages/task.scss";
@import "pages/user-profile.scss";
@import "pages/wishlist.scss";


/* ---------------------
	*** Themes ***
-----------------------*/

@import "themes/dark.scss";
@import "themes/dark-sidebar.scss";
@import "themes/theme-customizer.scss";


/* ---------------------
	*** Layout ***
-----------------------*/

@import "layout/footer.scss";
@import "layout/grid.scss";
@import "layout/header.scss";
@import "layout/navs.scss";
@import "layout/search.scss";
@import "layout/select2.scss";
@import "layout/sidebar.scss";
@import "layout/rtl.scss";
@import "layout/box-layout.scss";


/* ---------------------
	*** Transition ***
-----------------------*/

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

::view-transition-old(root) {
  animation: 130ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

/* ---------------------
	*** Angular-calendar angular calendar ***
-----------------------*/
@import "../node_modules/angular-calendar/css/angular-calendar.css";


/* ---------------------
	*** Emoji picker ***
-----------------------*/
@import '@ctrl/ngx-emoji-mart/picker';


.recent-circle {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin-top: 5px;
}

.recent-wrapper {
  align-items: center;

  .order-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;

    @media (max-width: 1199px) {
      gap: 20px;
    }

    @media (max-width: 575px) {
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
    }

    li {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  }

  .recent-chart {
    .apexcharts-canvas {
      .apexcharts-datalabel-label {
        @media (max-width: 1660px) {
          font-size: 15px;
        }
      }
    }
  }

  >div {
    @media (max-width: 1560px) and (min-width: 1400px) {
      width: 100%;
    }

    &:last-child {
      @media (max-width: 1560px) and (min-width: 1400px) {
        display: none;
      }
    }
  }
}